import axios from "axios"

export class RestService {
  constructor() {
    this.baseUrl = "http://lacrei-api-sandbox.herokuapp.com/v1"

    this._getHeaders = this._getHeaders.bind(this)
    this.fakePost = this.fakePost.bind(this)
    this.post = this.post.bind(this)
    this.get = this.get.bind(this)
    this.put = this.put.bind(this)
    this.patch = this.patch.bind(this)
    this.delete = this.delete.bind(this)
  }

  _getHeaders() {
    return {
      "Content-type": "application/json; charset=utf-8",
    }
  }

  async fakePost(url, body, headers = {}) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          status: 200,
          data: {},
        })
      }, 800)
    })
  }

  async post(url, body, headers = {}) {
    return await axios.post(`${this.baseUrl}${url}`, body, {
      headers: {
        ...this._getHeaders(),
        ...headers,
      },
    })
  }

  async get(url, params = {}, headers = {}) {
    return await axios.get(`${this.baseUrl}${url}`, {
      params: {...params},
      headers: {
        ...this._getHeaders(),
        ...headers,
      },
    })
  }

  async put(url, body, headers = {}) {
    return await axios.put(`${this.baseUrl}${url}`, body, {
      headers: {
        ...this._getHeaders(),
        ...headers,
      },
    })
  }

  async patch(url, body, headers = {}) {
    return await axios.patch(`${this.baseUrl}${url}`, body, {
      headers: {
        ...this._getHeaders(),
        ...headers,
      },
    })
  }

  async delete(url, headers = {}) {
    return await axios.delete(`${this.baseUrl}${url}`, {
      headers: {
        ...this._getHeaders(),
        ...headers,
      },
    })
  }
}
