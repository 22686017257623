import React, { useState } from "react"
import { useRandomIds } from "../../../../functions/useRandomIds"
import { Anchor, Checkbox, Container, Label } from "./styles"

export function CheckboxTerm({
  isClicked,
  error,
  ...props
}) {

  const id = useRandomIds()
  const keyNames = ["Space", "Enter", "NumpadEnter"];
  return (
    <Container>
    
      <Checkbox
        {...props}
        isClicked={isClicked}
        id={id("checkbox-terms")}
        role="checkbox"
        tabIndex={0}
        aria-checked={isClicked}
        aria-required={true}
        onKeyDown={event => {
          if (keyNames.includes(event.code)) {
           props.onClick()
          }
        }}
      > 
        {isClicked && "✓"}
      </Checkbox>
      <Label htmlFor={id("checkbox-terms")}>
        Concordo com os{" "}
        <Anchor href="#" target={"_blank"}>
          Termos de uso
        </Anchor>{" "}
        e<br></br>
        <Anchor href="#" target={"_blank"}>
          Política de Privacidade
        </Anchor>{" "}da Lacrei Saúde
      </Label>
      {error && <span>{error}</span>}

    </Container>
  )
}
