import { useCallback, useMemo, useState } from "react"

export function useServiceMethod(Service, method) {
  const [service] = useState(new Service())
  const [status, setStatus] = useState("waiting")
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  const call = useCallback(
    async (...params) => {
      setStatus("running")
      try {
        const r = await service[method](...params)

        if (r.status === 200) {
          setStatus("success")
          setResponse(r.data)
        } else {
          setStatus("failure")
          setError({
            status: r.status,
            statusText: r.statusText,
          })
          setResponse(r.data)
        }
      } catch (e) {
        setStatus("excepted")
        setError(e)
      }
    },
    [service, method]
  )

  return useMemo(
    () => ({
      status,
      error,
      response,
      call,
    }),
    [status, error, response]
  )
}
