import styled from "styled-components"
import colors from "../../../../styles/tokens/colors"

export const Container = styled.div`
  display: flex;
  height: 4.5rem;
  gap: 0.75rem;
  width: 33.438rem;
  padding: 0.75rem 0.5rem;
`

export const Checkbox = styled.span`
  align-items: center;
  background-color: ${({ isClicked }) =>
    isClicked && colors.secondary.health_green};
  border: 1.2px solid ${colors.secondary.health_green};
  border-radius: 2px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  font-weight: 900;
  height: 1.25rem;
  justify-content: center;
  min-width: 1.25rem;
  width: 1.25rem;
`

export const Label = styled.label`
  color: ${colors.gray.dark};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
`

export const Anchor = styled.a`
  cursor: pointer;
  color: ${colors.secondary.health_green};
  text-decoration: none;
`
