import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import ProImg from "../../assets/images/profissional-saude3.svg"
import { Dropdown } from "../../components/profissionaisSaude/Inputs/Dropdown"
import { jobsData } from "../../utils"
import Header from "../../components/profissionaisSaude/Header"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import { CheckboxTerm } from "../../components/profissionaisSaude/Inputs/CheckboxTerm"
import { TextInput } from "../../components/profissionaisSaude/Inputs/InputText"
import Footer from "../../components/profissionaisSaude/Footer"
import CheckPasswords from "../../components/profissionaisSaude/Inputs/CheckPasswords"
import { useServiceMethod } from "../../services/common/use-service-method"
import { PreSignUpService } from "../../services/profissional/pre-sign-up"

const Container = styled.div`
  padding: 0 6.875rem;

  section {
    width: 100%;
    max-width: 68.75rem;
    min-height: calc(100vh - 13.75rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  .screen-side {
    width: 100%;
    max-width: 33.75rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;

    h1 {
      margin-top: 2rem;
      font-size: 1.5rem;
    }

    > p {
      margin-top: -0.5rem;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .button-container {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .checkbox-term {
        color: #ff0000;
        margin: -0.5rem 0 1rem 0;
      }
    }

    .password-details {
      padding: 1.5rem 0 1.5rem 0.5rem;
    }

    .password-content li {
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: 0.5rem;
    }

    .password-content li:last-child {
      margin-bottom: 0;
    }
  }

  .img-wrapper {
    align-items: center;
    flex-direction: column;
    position: relative;
    display: none;
    padding-bottom: 0.5rem;

    span,
    a {
      color: #515151;
      font-size: 0.75rem;
    }

    a {
      text-decoration: underline;
    }

    @media screen and (min-width: 425px) {
      display: flex;
    }
  }

  .pro-image {
    max-width: 30.688rem;
    max-height: 40.875rem;
  }

  .caption-image {
    position: absolute;
    bottom: -3.649rem;
    right: 2rem;
    font-size: 1.125rem;
  }
`

export default function Cadastro() {
  const regexEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i
  const [isSelectActive, setIsSelectActive] = useState(false)
  const [isPasswordInputActive, setIsPasswordInputActive] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [isCheckboxTermClicked, setIsCheckboxTermClicked] = useState(false)

  const [formData, setFormData] = useState({
    accepted_privacy_document: false,
    email: "",
    password1: "",
    password2: "",
    full_name: "",
    profession: "",
    board_prefix: 1,
    board_registration_number: "",
    state: 28,
  })

  const [formErrors, setFormErrors] = useState({
    accepted_privacy_document: "",
    email: "",
    full_name: "",
    profession: "",
    board_prefix: "",
    board_registration_number: "",
    password1: "",
    password2: "",
    state: "",
  })

  const [alreadyInteracted, setAlreadyInteracted] = useState({
    full_name: false,
    email: false,
    profession: false,
    board_registration_number: false,
    password1: false,
    password2: false,
    accepted_privacy_document: false,
  })

  const validate = () => {
    const errors = {}

    const isAcceptedPrivacyDocumentInvalid = !formData.accepted_privacy_document
    const isEmailInvalid = !regexEmail.test(formData.email)
    const isFullNameInvalid = formData.full_name.length === 0
    const isProfessionInvalid = !formData.profession
    const isBoardPrefixInvalid = !formData.board_prefix
    const isBoardRegistrationNumberInvalid = !formData.board_registration_number
    const isStateInvalid = !formData.state
    const isInvalidPassword1 = formData.password1.length === 0
    const isInvalidPassword2 = formData.password2.length === 0

    if (isAcceptedPrivacyDocumentInvalid) {
      errors.accepted_privacy_document =
        "Você precisa aceitar os termos de privacidade."
    }

    if (isEmailInvalid) {
      setAlreadyInteracted.full_name = true
      errors.email =
        "Por favor, utilize um formato de e-mail válido. Por exemplo: email@dominio.com.br"
    }

    if (isFullNameInvalid) {
      setAlreadyInteracted.email = true
      errors.full_name = "O campo “Nome Civil ou Social” é obrigatório"
    }

    if (isProfessionInvalid) {
      setAlreadyInteracted.profession = true
      errors.profession = "O campo “Profissão” é obrigatório"
    }

    if (isBoardPrefixInvalid) {
      errors.board_prefix = "Este campo é obrigatório!"
    }

    if (isBoardRegistrationNumberInvalid) {
      setAlreadyInteracted.board_registration_number = true
      errors.board_registration_number = "Este campo é obrigatório!"
    }

    if (isInvalidPassword1) {
      setAlreadyInteracted.password1 = true
      errors.password1 = "Este campo é obrigatório!"
    }

    if (isInvalidPassword2) {
      setAlreadyInteracted.password2 = true
      errors.password2 = "Este campo é obrigatório!"
    }

    if (!formValid) {
      errors.formValid = true
      if (alreadyInteracted.password1) {
        setIsPasswordInputActive(true)
      }
    }

    // não aparece essa opção
    if (isStateInvalid) {
      errors.state = "Você precisa informar seu estado."
    }

    setFormErrors(errors)
    const isFormValid = Object.keys(errors).length === 0
    if (!isFormValid) {
      setAlreadyInteracted.full_name = true
    }
    setFormValid(isFormValid)

    return isFormValid
  }

  const handleChange = async e => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
    setAlreadyInteracted(prev => ({ ...prev, [name]: true }))
  }

  useEffect(() => {
    validate()
  }, [formData])

  const preSignUp = useServiceMethod(PreSignUpService, "preSignUp")

  useEffect(() => {
    if (preSignUp.status !== "waiting" && preSignUp.status !== "running") {
      navigate("/saude/verificacao-conselho-de-classe")
    }
  }, [preSignUp.status])

  useEffect(() => {
    const isAnyInputActive = formData.password1 || formData.password2
    const inputIsDifferent = formData.password1 !== formData.password2
    if (isAnyInputActive || inputIsDifferent) {
      setIsPasswordInputActive(true)
    }
  }, [formData.password1, formData.password2])

  const handleSubmitForm = useCallback(
    event => {
      event.preventDefault()
      if (event.nativeEvent.submitter.innerHTML === "Próximo") {
        setAlreadyInteracted({
          full_name: true,
          email: true,
          profession: true,
          board_registration_number: true,
          password1: true,
          password2: true,
          accepted_privacy_document: true,
        })
        setIsCheckboxTermClicked(true)
      }
      const isValid = validate()
      if (isValid) preSignUp.call({ ...formData })
    },
    [formData, preSignUp, validate]
  )

  return (
    <Container>
      <Header />
      <section>
        <div className="screen-side">
          <h1>Crie sua conta Lacrei Saúde</h1>
          <p>Preencha as informações solicitadas para criar seu perfil.</p>
          <form onSubmit={handleSubmitForm}>
            <TextInput
              onFocus={() => setFormErrors({ ...formErrors, full_name: "" })}
              onBlur={validate}
              onChange={handleChange}
              name="full_name"
              value={formData.full_name}
              error={alreadyInteracted.full_name && formErrors.full_name}
              placeholder="Digite seu nome civil ou social completo"
              label="Nome Civil ou Social Completo"
              large={true}
            />
            <TextInput
              onFocus={() => setFormErrors({ ...formErrors, email: "" })}
              onChange={handleChange}
              onBlur={validate}
              name="email"
              value={formData.email}
              placeholder="e-mail@dominio.com.br"
              label="E-mail"
              error={alreadyInteracted.email && formErrors.email}
              large={true}
            />
            <Dropdown
              selectedValue={formData.profession}
              data={jobsData}
              name="profession"
              onSelectOption={value => {
                setFormData(prev => ({ ...prev, profession: value }))
                setIsSelectActive(false)
              }}
              isSelectActive={isSelectActive}
              onOpenSelect={() => {
                setIsSelectActive(!isSelectActive)
                setFormErrors({ ...formErrors, profession: "" })
              }}
              isError={alreadyInteracted.profession && formErrors.profession}
              large={true}
            />
            <TextInput
              onFocus={() =>
                setFormErrors({ ...formErrors, board_registration_number: "" })
              }
              onChange={handleChange}
              name="board_registration_number"
              value={formData.board_registration_number}
              placeholder="Digite seu número de inscrição profissional"
              label="Número de Incrição Profissional"
              error={
                alreadyInteracted.board_registration_number &&
                formErrors.board_registration_number
              }
              large={true}
            />
            <TextInput
              onChange={handleChange}
              name="password1"
              value={formData.password1}
              placeholder="Digite sua senha"
              label="Senha"
              isPassword
              large={true}
              onFocus={() => setIsPasswordInputActive(true)}
              onBlur={() => setIsPasswordInputActive(false)}
              error={alreadyInteracted.password1 && formErrors.password1}
            />
            <TextInput
              onChange={handleChange}
              value={formData.password2}
              name="password2"
              placeholder="Confirme sua senha"
              label="Confirmar senha"
              isPassword
              large={true}
              onFocus={() => setIsPasswordInputActive(true)}
              onBlur={() => setIsPasswordInputActive(false)}
              error={alreadyInteracted.password2 && formErrors.password2}
            />
            <CheckPasswords
              passwords={{
                password: formData.password1,
                passwordConfirmation: formData.password2,
                isActive: isPasswordInputActive,
              }}
              onValid={value => {
                setFormValid(value)
              }}
            />
            <CheckboxTerm
              onClick={() => {
                setIsCheckboxTermClicked(true)
                setFormData(prev => ({
                  ...prev,
                  accepted_privacy_document:
                    !formData.accepted_privacy_document,
                }))
                setFormErrors({ ...formErrors, accepted_privacy_document: "" })
              }}
              isClicked={formData.accepted_privacy_document}
            />
            {isCheckboxTermClicked && !formData.accepted_privacy_document && (
              <p className="checkbox-term">
                Você precisa aceitar os termos de privacidade.
              </p>
            )}
            <Button
              title="Próximo"
              type="submit"
              large={true}
              loading={preSignUp.status === "running"}
            />
            <Button
              title="Voltar"
              link={"/saude/cadastrar-estado"}
              variant="secondary"
              large={true}
              loading={preSignUp.status === "running"}
            />
          </form>
        </div>
        <div className="img-wrapper">
          <ProImg className="pro-image" alt="Descrição da imagem" />
          <span className="caption-image">
            Ilustrado por{" "}
            <a href="https://br.freepik.com/">Freepik</a>
          </span>
        </div>
      </section>
      <Footer hasButtonUp />
    </Container>
  )
}
