import { RestService } from "../common/rest-service"

export class PreSignUpService extends RestService {
  constructor() {
    super()

    this.createUser = this.createUser.bind(this)
    this.getUser = this.getUser.bind(this)
    this.updateUser = this.updateUser.bind(this)
    this.updateUserPartially = this.updateUserPartially.bind(this)
  }

  /**
    Função para realizar o pré-cadastro da pessoa profissional

    @param {object} requestBody - Objeto com os dados para realizar o pré-cadastro 

    @returns {promise} Dados cadastrados
  */
  async createUser(requestBody) {
    return await this.post("/lacreiid/user/", requestBody)
  }

  /**
    Função para visualizar os dados de usuário da pessoa profissional

    @returns {promise} Dados de usuário da pessoa profissional
  */
  async getUser() {
    return await this.get("/lacreiid/user/")
  }

  /**
   Função para atualizar os dados de usuário da pessoa profissional

   @param {object} requestBody - Objeto com todos os dados para atualização 

   @returns {promise} Dados atualizados
  */
  async updateUser(requestBody) {
    return await this.put("/lacreiid/user/", requestBody)
  }

  /**
   Função para atualizar parcialmente os dados de usuário da pessoa profissional

   @param {object} requestBody - Objeto com alguns ou todos os dados para atualização parcial 

   @returns {promise} Dados atualizados
  */
  async updateUserPartially(requestBody) {
    return await this.patch("/lacreiid/user/", requestBody)
  }
}
